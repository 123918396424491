<template>
   <el-container>
      <el-main>
         <About />
         <el-row :gutter="12" type="flex" justify="center">
            <el-col :span="6" class="form">
               <el-card class="box-card login">
                  <template #header>
                     <div class="card-header">
                        <span>Вход</span>
                     </div>
                  </template>
                  <el-form :model="loginForm" :rules="rules" ref="loginForm">
                        <el-form-item prop="email">
                              <el-input
                                 type="text"
                                 v-model.trim="loginForm.email"
                                 placeholder="E-mail"
                              ></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                              <el-input
                                 type="password"
                                 v-model.trim="loginForm.password"
                                 placeholder="Пароль"
                              ></el-input>
                        </el-form-item>
                        <el-form-item>
                              <el-button @click="onLogin()">Войти</el-button>
                           </el-form-item>
                           <el-form-item>
                              <el-button>
                                 <router-link to="/signup">Зарегистрироваться</router-link>
                              </el-button>
                           </el-form-item>
                           <el-link>
                              <router-link :underline="false" to="/forgot">Забыли пароль ?</router-link>
                           </el-link>
                  </el-form>
               </el-card>
            </el-col>
         </el-row>
         <el-row type="flex" justify="center" align="bottom" class="oferta">
            <el-card class="box-card">
               <div class="text item">ИП Кожухова Е.В.</div>
               <div class="text item">ИНН 525911460618</div>
               <div class="text item">ОГРНИП 316525900050872</div>
               <div class="text item">тел.: +7 (926) 722-79-79</div>
               <div class="text item">e-mail:
                  <a href="mailto:let.tobe.happy@gmail.com">let.tobe.happy@gmail.com</a>
               </div>
               <div class="text item" style="margin-top: 10px;">
                  <a href="https://docs.google.com/file/d/161u4PRjd9D8kRQXdiRbs7MzSmnQqt1L_/edit?usp=docslist_api&filetype=msword" target="_blank">Полититка конфедициальности</a>
               </div>
               <div class="text item">
                  <a href="https://drive.google.com/file/d/1gKbrzN6eifZlhVFasbnih1TsFvgVj8tk/view?usp=sharing" target="_blank">Договор оферты</a>
               </div>
            </el-card>
         </el-row>
      </el-main>
   </el-container>
</template>
<script>

import { ElMessage } from 'element-plus';
import { mapActions, mapMutations } from 'vuex';
import {
    LOADING_SPINNER_SHOW_MUTATION,
    LOGIN_ACTION,
} from '../store/storeconstants';
import About from '../components/About';

export default {
    data() {
        return {
            errors: [],
            error: '',
            loginForm: {
               email: '',
               password: '',
            },
            rules: {
               email: [
                  { required: true, message: 'Поле email не может быть пустым', trigger: 'blur' },
                  { type: 'email', message: 'Email адрес не корректный', trigger: ['blur', 'change'] }
               ],
               password: [
                  { required: true, message: 'Поле пароль не может быть пустым', trigger: 'blur' },
                  { min: 6, message: 'Длина пароля минимум 6 символов', trigger: 'blur' }
               ]
            },
            drawer: false,
            direction: 'ltr',
        };
    },
    components: {
      About,
    },
    methods: {
        ...mapActions('auth', {
            login: LOGIN_ACTION,
        }),
        ...mapMutations({
            showLoading: LOADING_SPINNER_SHOW_MUTATION,
        }),
        async onLogin() {
            this.showLoading(true);
            //Login check
            try {
                await this.login({
                    email: this.loginForm.email,
                    password: this.loginForm.password,
                });

                this.$router.push('/courses');
            } catch (error) {
                this.error = error;
               //  this.showLoading(false);
                  ElMessage({
                     type: 'warning',
                     message: `${this.error}`,
                  });
            }

            this.showLoading(false);
        },
    },
};
</script>

<style>
   .logo {
      margin: 15px 0px 35px;
   }
</style>

<style scoped>
   .el-container {
         background: #5E597B;
         height: 100%;
      }
   .el-main {
      color: #333;
      height: 100%;
   }
   .form .el-button {
      width: 100%;
   }
   .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .oferta {
     height: 33%;
  }
  .oferta .box-card {
     border: 1px solid transparent;
     background-color: initial;
     box-shadow: none;
     color: #c5c4c4;
     text-align: center;
  }
  .oferta a {
     text-decoration: underline;
  }
  .about {
     position: absolute;
     left: 0;
     background: none;
     border: none;
  }
  .close {
     float: right;
     background: none;
     border: none;
     margin: 20px;
  }
  .el-icon-arrow-left {
     font-size: 50px;
     color: #606266;
  }
  .el-icon-arrow-right {
     font-size: 50px;
     color: #ffffff;
  }
  .about-description {
     width: 100%;
     background: none;
     border: none;
     box-shadow: none;
  }
  .el-icon-circle-check {
     margin: 10px;
  }
  .text.item,
  .list {
     font-family: 'Montserrat', sans-serif!important;
      font-weight: 300!important;
      font-style: normal!important;
      font-size: 14px!important;
  }
  .list {
      font-size: 12px!important;
  }
</style>
