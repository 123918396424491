<template>
   <el-row type="flex" justify="center">
            <el-button @click="drawer = true" class="about">
               <i class="el-icon-arrow-right"></i>
            </el-button>
            <el-image :src="require('@/assets/logo.svg')" class="logo"></el-image>

            <el-drawer
               title="I am the title"
               v-model="drawer"
               :direction="direction"
               :with-header="false">

               <el-button @click="drawer = false" class="close">
                  <i class="el-icon-arrow-left"></i>
               </el-button>

               <el-card class="box-card about-description">
                  <template #header>
                  <div class="card-header">
                     <span>Добро пожаловать в школу самопознания Betterlife!</span>
                  </div>
                  </template>
                  <div class="text item">Здесь вы найдёте обучение нумерологии по системе Матрица судьбы для разного уровня подготовленности:</div>
                     <ul class="list">
                        <li>для новичков;</li>
                        <li>для продвинутых;</li>
                        <li>для экспертов;</li>
                        <li>для проводников;</li>
                     </ul>
                  <div class="text item">Вводные уроки по каждому уровню доступны бесплатно после регистрации.</div>
                  <el-divider></el-divider>
                  <div class="text item">𝐁𝐄𝐓𝐓𝐄𝐑𝐋𝐈𝐅𝐄 начинается с тебя!</div>
               </el-card>

            </el-drawer>
         </el-row>
</template>

<script>

export default {
    data() {
        return {
            drawer: false,
            direction: 'ltr',
        };
    }
};
</script>

<style>
   .el-drawer {
     box-shadow: inset -6px 0px 8px -6px #606266;
   }
    @media only screen and (max-width: 1400px) {
      .el-drawer {
         width: 40%!important;
      }
   }
    @media only screen and (max-width: 992px) {
      .el-drawer {
         width: 50%!important;
      }
   }
   @media only screen and (max-width: 768px) {
      .el-drawer {
         width: 90%!important;
      }
   }
</style>

<style scoped>
  .about {
     position: absolute;
     left: 0;
     background: none;
     border: none;
  }
  .close {
     float: right;
     background: none;
     border: none;
     margin: 20px;
  }
  .el-icon-arrow-left {
     font-size: 50px;
     color: #606266;
  }
  .el-icon-arrow-right {
     font-size: 50px;
     color: #ffffff;
  }
  .about-description {
     width: 100%;
     background: none;
     border: none;
     box-shadow: none;
  }
  .el-icon-circle-check {
     margin: 10px;
  }
  .text.item,
  .list {
     font-family: 'Montserrat', sans-serif!important;
      font-weight: 300!important;
      font-style: normal!important;
      font-size: 14px!important;
  }
  .list {
      font-size: 12px!important;
  }
</style>
